import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`たまに、`}</p>
      <p>{`「ピアノは700をいじるのですよね？」`}<br parentName="p"></br>{`
`}{`「ギターだから5kをいじりました」`}</p>
      <p>{`という方がおられるのですが、これは違います。`}<br parentName="p"></br>{`
`}{`「この楽器だから、この周波数を使え」`}<br parentName="p"></br>{`
`}{`という意味ではありません。`}</p>
      <p>{`九九は、全ての楽器に共通して`}<br parentName="p"></br>{`
`}{`「エッジ感を出したいときは、楽器に関係なく５k」`}<br parentName="p"></br>{`
`}{`「丸みを出したいときは、楽器に関係なく700」`}<br parentName="p"></br>{`
`}{`というふうに使うものです。`}</p>
      <p>{`巷では「この楽器は、この周波数を使え！」と教えている教材が多いので、そう思ってしまうのかもしれません。こういう教え方は「テストの答え丸暗記」と同じなので、実戦では全く使えません。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      