import React from "react"
import { Link } from "gatsby"
import SEO from '../../../components/seo'

import Elements from '../../../layouts/elements'
import Hosoku from '@components/md-components/hosoku/4-10.md'

const youso=	4
const part=		10
const title=	'エッジの5k'
const url=		'271258053'

const iti=		youso + '-' +part
const h1=		'【' + iti + '】' + title
const mae= 		'/elements/'+youso+'/'+youso +'-'+(part-1)+'/'
const ato= 		'/elements/'+youso+'/'+youso +'-'+(part+1)+'/'
const mae_part= '/elements/'+(youso-1)+'/'+(youso-1)
const ato_part= '/elements/'+(youso+1)+'/'+(youso+1) +'-1/'



export default () => (

<Elements
	mae={mae}
	ato={ato}
	url={url}
	h1={h1}
>

<SEO title={title}/>
<Hosoku/>


</Elements>
)